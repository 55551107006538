import { Link } from 'react-router-dom'
import { FaRegArrowAltCircleUp } from 'react-icons/fa';

function Footer() {
    return (
        <div className="container-fluid footer p-3">
            <footer className="d-flex flex-wrap justify-content-between align-items-center">
                <p className="col-4 mb-0 fm-informatica">
                    <a href="http://fminformatica.com.ar" rel="noreferrer"  target="_blank" >&copy; FM Informática</a>
                </p>

                <Link to="/" className='col-4 mb-0 text-center'>
                    <img src="./img/el-rebusque-logo.jpg" className="bg-white rounded p-1" alt="Supermercado El rebusque" width="100px" />
                </Link>

                <div className='col-4 mb-0 text-end'>
                    <FaRegArrowAltCircleUp className='back-top' onClick={() => window.scrollTo(0, 0)} title='Volver al inicio' />
                </div>
            </footer>
        </div>
    )
}

export default Footer
