import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import './App.css';
import './Carousel.css';
import ReactGA from 'react-ga';
import Footer from './layout/Footer';
import NavBar from './layout/NavBar';
import Home from "./pages/Home";
import Store from "./pages/Store";
import We from "./pages/We";
import Contact from "./pages/Contact";
import Cart from "./pages/Cart";
import toast, { Toaster } from 'react-hot-toast';
import ItemDetaills from "./components/ItemDetaills";

function App() {
  const TRACKING_ID = "UA-232632101-1";
  ReactGA.initialize(TRACKING_ID);

  const [globalSearch, setGlobalSearch] = useState('');

  const itemsUrl0 = process.env.REACT_APP_BACKEND_URL + "/admin/app/tablas/items/modelo.php?op=getall&discount=0";
  const itemsUrl5 = process.env.REACT_APP_BACKEND_URL + "/admin/app/tablas/items/modelo.php?op=getall&discount=5";
  const itemsUrl10 = process.env.REACT_APP_BACKEND_URL + "/admin/app/tablas/items/modelo.php?op=getall&discount=10";
  const [itemsUrlWithDiscount, setItemsUrlWithDiscount] = useState(null);

  const [items, setItems] = useState([]);
  const [itemsInCart, setItemsInCart] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('cash/transfer');
  const [appLoading, setAppLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const KEYItems = "rebusqueAPP.itemsInCart";
  const KEYShowAs = "rebusqueAPP.showAs";
  const [showAs, setShowAs] = useState('card');

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (paymentMethod === 'cash/transfer') setItemsUrlWithDiscount(itemsUrl10);
    if (paymentMethod === 'debit' || paymentMethod === 'credit') setItemsUrlWithDiscount(itemsUrl5);
    if (paymentMethod === 'cc') setItemsUrlWithDiscount(itemsUrl0);
  }, [paymentMethod])

  useEffect(() => {
    const storedItemsInCart = JSON.parse(localStorage.getItem(KEYItems));
    const storedShowAs = JSON.parse(localStorage.getItem(KEYShowAs));
    if (storedItemsInCart !== null) {
      setItemsInCart(storedItemsInCart);
    }
    if (storedShowAs !== null) {
      setShowAs(storedShowAs);
    }
    setAppLoading(false);
  }, []);

  useEffect(() => {
    if (appLoading) return;
    localStorage.setItem(KEYItems, JSON.stringify(itemsInCart));
    // eslint-disable-next-line
  }, [itemsInCart]);

  useEffect(() => {
    if (appLoading) return;
    localStorage.setItem(KEYShowAs, JSON.stringify(showAs));
    // eslint-disable-next-line
  }, [showAs]);

  const updateGlobalSearch = (newValue) => {
    setGlobalSearch(newValue);
    if (newValue !== '' && location.pathname !== "/store") {
      navigate("/store");
    }
  }

  const updateItemsQuantity = (idItem, quantity, modify, fractionable) => {
    if (typeof quantity === 'undefined') quantity = 0;
    if (fractionable === 1) {
      modify = parseFloat(modify / 10).toFixed(2);
      quantity = Number(parseFloat(quantity) + parseFloat(modify)).toFixed(2);
    }
    else {
      quantity = Number(parseFloat(quantity) + parseFloat(modify)).toFixed(0);
    }
    if (quantity < 0) quantity = 0;
    //Make a copy of current items in cart
    const tempItemsInCart = [...itemsInCart];
    //Find current item with ID
    const itemToUpdate = tempItemsInCart.find((currentItem) => currentItem["id"] === idItem);
    //If Item not exist, add it
    if (typeof itemToUpdate === 'undefined') {
      if (quantity > 0) {
        tempItemsInCart.push({ "id": idItem, "quantity": quantity });
        toast.success("Producto agregado al carrito");
      }
    }
    else {
      //If Item exist, update quantity
      if (quantity == 0 ) {
        toast.success("Producto eliminado del carrito");
      }
      itemToUpdate["quantity"] = quantity;
    }
    //Remove items with quantity <=0
    setItemsInCart(tempItemsInCart.filter((currentItem) => currentItem["quantity"] > 0));

    //Update Items with new quantity
    const newItems = [...items];
    const newItemToUpdate = newItems.find((currentItem) => currentItem["id"] === idItem);
    //If Item not exist, add it
    if (typeof newItemToUpdate !== 'undefined') {
      newItemToUpdate["quantity"] = quantity;
      setItems(newItems);
    }
  }

  const clearCart = () => {
    setItemsInCart([]);
  }

  return (
    <>
      <div><Toaster position="bottom-center" /></div>
      <div className="App">
        <NavBar updateGlobalSearch={updateGlobalSearch} updateItemsQuantity={updateItemsQuantity} itemsInCart={itemsInCart} />
        <div className="splitter"></div>
        <div className="wrapper">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="store" element={<Store const showAs={showAs} setShowAs={setShowAs} appLoading={appLoading} globalSearch={globalSearch} itemsUrlWithDiscount={itemsUrlWithDiscount} items={items} setItems={setItems} itemsInCart={itemsInCart} paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} updateItemsQuantity={updateItemsQuantity} />} />
            <Route path="we" element={<We />} />
            <Route path="contact" element={<Contact />} />
            <Route path="cart" element={<Cart showAs={showAs} setShowAs={setShowAs} clearCart={clearCart} itemsUrlWithDiscount={itemsUrlWithDiscount} itemsInCart={itemsInCart} paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} updateItemsQuantity={updateItemsQuantity} />} />
            <Route path="itemdetaills" element={<ItemDetaills showAs={showAs} appLoading={appLoading} setShowAs={setShowAs} clearCart={clearCart} itemsUrlWithDiscount={itemsUrlWithDiscount} itemsInCart={itemsInCart} updateItemsQuantity={updateItemsQuantity} />} />
          </Routes>
        </div>
        <div className="splitter"></div>
        <Footer />
      </div>
    </>
  );

}
export default App;
