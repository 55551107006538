import { FaWhatsapp } from 'react-icons/fa'
import Location from '../components/Location'


function Contact() {
  return (
    <div className="contact-section p-0 m-0 w-100">

      <div className='content p-0 m-0 row w-100'>
        <div className='con col-12 col-md-6 d-flex flex-column justify-content-center align-items-center'>
          <img src="./img/el-rebusque-logo.jpg" className="rounded mx-auto d-block mw-70" alt="El Rebusque"></img>

          <h3>0353 - 4868972</h3>
          <h3>0353 - 155633858</h3>
          <span className='d-none d-md-block'>
            <a href="https://web.whatsapp.com/send?phone=543535633858&text=Hola" className='whatsapp-desktop' target="_blank" rel="noopener noreferrer">
              <FaWhatsapp />Envíar un mensaje ahora
            </a>
          </span>
          <span className='d-block d-md-none'>
            <a
              href="https://api.whatsapp.com/send?phone=543535633858&text=Hola" className='whatsapp-phone' target="_blank" rel="noopener noreferrer">
              <FaWhatsapp />Envíar un mensaje ahora
            </a>
          </span>
        </div>

        <div className='con col-12 col-md-6'>
          <h3 className='text-center mt-3 mb-2'>¿Donde estamos?</h3>
          <Location />
        </div>
      </div>

    </div>
  )
}

export default Contact
