import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Loading from './Loading';
function SlideWe() {
    const slidesUrl = process.env.REACT_APP_BACKEND_URL + "/admin/app/tablas/detalledeslides/modelo.php?op=getall&slide=2";
    const imagesUrl = process.env.REACT_APP_BACKEND_URL + "/admin/app/uploads/";
    const [slides, setSlides] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getAllSlides();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllSlides = async () => {
        const response = await axios.get(slidesUrl);
        setSlides(response.data.data);
        setLoading(false)
    }
    return (
        <>
            {loading ? (
                < Loading />
            ) :
                (
                    <div id="carouselWe" className="carousel carouselWe slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            {
                                slides.map((slide, index) => (
                                    <button key={"bt" + index} type="button" data-bs-target="#carouselWe" data-bs-slide-to={index} className={index === 0 ? "secondary active" : "secondary"} aria-current={index === 0 ? "true" : ""} aria-label={"Slide " + index}></button>
                                )
                                )
                            }
                        </div>
                        <div className="carousel-inner">
                            {
                                slides.map((slide, index) => (
                                    <div key={"slide" + index} className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                        <img src={imagesUrl + slide["imagen"]} className="d-block w-100" alt="El Rebusque" />
                                        <div className="carousel-caption">
                                            <h3 className="blak">{slide["titulo"]}</h3>
                                            <p className="blak">{slide["detalle"]}</p>
                                        </div>
                                    </div>
                                )
                                )
                            }
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselWe" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselWe" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                )
            }
        </>
    )
}

export default SlideWe
