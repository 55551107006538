import SlideWe from './SlideWe'

function We() {
    return (
        <div>
            <div className='content p-0 m-0 row w-100'>
                <SlideWe />
            </div>
            <div className='content p-2 pt-5 pb-5 m-0 row w-100 we-text'>
                <div className='con col-12 text-center'>
                    <p className="text-justify">Ubicado en Urquiza y Sarmiento, en la localidad de Pozo del Molle, CBA.
                        Supermercado El Rebusque, acompaña el día a día en el hogar desde 1989.
                        Con una amplia variedad de productos y marcas junto a una cordial atención.
                        Escuchando siempre a nuestros clientes y buscando la manera de brindar lo que necesita.</p>
                </div>
            </div>
        </div>
    )
}

export default We
