import axios from 'axios';
import React, { useEffect, useState } from 'react'
import * as bootstrap from 'bootstrap';
import Loading from './Loading';

import ItemCards from './ItemsCards';
import ItemTable from './ItemsTable';

import { FaThList, FaRegIdCard, FaQuestionCircle, FaCreditCard, FaExchangeAlt, FaMoneyCheck, FaMoneyBillAlt, FaCcMastercard } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import { IconContext } from 'react-icons';

import toast, { Toaster } from 'react-hot-toast';

function Items({ showAs, setShowAs, appLoading, globalSearch, itemsUrlWithDiscount, items, setItems, itemsInCart, paymentMethod, setPaymentMethod, updateItemsQuantity }) {
    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchIn, setSearchIn] = useState('name');
    const [orderBy, setOrderBy] = useState('name');
    const [loading, setLoading] = useState(true);
    const [searching, setSearching] = useState(true);
    const [changingPaymentMethod, setChangingPaymentMethod] = useState(false);

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        })
    }, [])

    useEffect(() => {
        getAllItems(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalSearch, orderBy, searchIn])

    useEffect(() => {
        if (!searching) {
            getAllItems(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, itemsUrlWithDiscount])

    useEffect(() => {
        if (!appLoading)
            getAllItems(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appLoading]);

    const getAllItems = async (resetPage) => {
        if (itemsUrlWithDiscount === null) return;
        setLoading(true);
        setSearching(true);
        var query = "";
        if (resetPage) {
            setCurrentPage(1);
            query = itemsUrlWithDiscount + "&search=" + globalSearch + "&currentPage=" + 1 + "&searchIn=" + searchIn + "&orderBy=" + orderBy;
        }
        else {
            query = itemsUrlWithDiscount + "&search=" + globalSearch + "&currentPage=" + currentPage + "&searchIn=" + searchIn + "&orderBy=" + orderBy;
        }

        //Peticionar datos actualizados
        const response = await axios.get(query);
        //Restaurar items agregados
        const newItems = [...response.data.data];
        itemsInCart.forEach(itemInCart => {
            const item = newItems.find((item) => item["id"] === itemInCart["id"]);
            if (typeof item !== 'undefined')
                item["quantity"] = itemInCart["quantity"];
        });

        setItems(newItems);
        setPages(response.data.pages);
        setSearching(false);
        setLoading(false)
    }

    const setPaymentMethodAndHideOptions = (_paymentMethod, _paymentMethodText) => {
        setPaymentMethod(_paymentMethod);
        setChangingPaymentMethod(false);
        toast.success("Método de pago: " + _paymentMethodText, {
            duration: 5000,
            position: 'top-center'
        });
    }

    return (
        <>
            <div className='row mx-auto bg-light pt-1 pb-1'>
                <span className='col col-5 col-xl-4'>
                    {globalSearch !== "" ?
                        <div className="flex-row">
                            <p className='m-2'>
                                Resultados para '{globalSearch}'
                            </p>
                            <a data-tip data-for='searchTip' className='pt-2'>
                                <FaQuestionCircle />
                            </a>
                            <ReactTooltip id="searchTip" type="info" effect="float">
                                Solo se tomarán en cuenta las primeras 4 letras de la búsqueda
                            </ReactTooltip>
                        </div>
                        : ""}
                </span>

                <span className='col col-7 col-xl-2 text-end pb-1 pt-1'>
                    <div className="btn-group" role="group" aria-label="Mostrar como">
                        {
                            changingPaymentMethod ?
                                (
                                    <>
                                        <IconContext.Provider value={{ size: "1.4em" }}>
                                            <button className={(paymentMethod === 'cash/transfer') ? 'btn btn-success' : 'btn btn-secondary'} title="Efectivo/Transferencia" onClick={() => setPaymentMethodAndHideOptions("cash/transfer", "Efectivo/Transferencia")}><FaMoneyBillAlt /></button>
                                            <button className={(paymentMethod === 'debit') ? 'btn btn-success' : 'btn btn-secondary'} title="Tarjeta de débito" onClick={() => setPaymentMethodAndHideOptions("debit", "Tarjeta de débito")}><FaCreditCard /></button>
                                            <button className={(paymentMethod === 'credit') ? 'btn btn-success' : 'btn btn-secondary'} title="Tarjeta de crédito" onClick={() => setPaymentMethodAndHideOptions("credit", "Tarjeta de crédito")}><FaCcMastercard /></button>
                                            <button className={(paymentMethod === 'cc') ? 'btn btn-success' : 'btn btn-secondary'} title="Cuenta corriente" onClick={() => setPaymentMethodAndHideOptions("cc", "Cuenta corriente")}><FaMoneyCheck /></button>
                                        </IconContext.Provider>
                                    </>
                                ) :
                                (
                                    <>
                                        <input type="radio" onChange={(e) => e.preventDefault()} onClick={() => setShowAs("table")} className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" />
                                        <label className={(showAs === 'table') ? 'btn btn-primary' : 'btn btn-secondary'} htmlFor="btnradio1" title="Mostrar en modo tabla"><FaThList /></label>

                                        <input type="radio" onChange={(e) => e.preventDefault()} onClick={() => setShowAs("card")} className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                                        <label className={(showAs === 'card') ? 'btn btn-primary' : 'btn btn-secondary'} htmlFor="btnradio2" title="Mostrar en modo tarjeta"><FaRegIdCard /></label>

                                        <button className='btn btn-primary ms-2' title="Cambiar tipo de pago" onClick={() => setChangingPaymentMethod(true)}>{(paymentMethod === 'cash/transfer') ? <FaMoneyBillAlt /> : null}{(paymentMethod === 'debit') ? <FaCreditCard /> : null}{(paymentMethod === 'credit') ? <FaCreditCard /> : null}{(paymentMethod === 'cc') ? <FaMoneyCheck /> : null}<FaExchangeAlt className='ms-2' /></button>
                                    </>
                                )
                        }

                    </div>

                </span>
                <span className='col p-0 pb-1 pt-1 col-6 col-xl-3 d-flex flex-column flex-xl-row'>
                    <label className="pt-2 ps-1 pe-1 lb-small text-start text-xl-end" htmlFor="orderby">Buscar en</label>
                    <select className="form-select m-0" onChange={(e) => setSearchIn(e.target.value)} defaultValue="name" name="searchin" aria-label="Busca en">
                        <option value="all">Todo</option>
                        <option value="categorie">Categoría</option>
                        <option value="name">Nombre</option>
                    </select>
                </span>
                <span className='col p-0 pb-1 pt-1 col-6 col-xl-3 d-flex flex-column flex-xl-row'>
                    <label className="pt-2 ps-1 pe-1 lb-small text-start text-xl-end" htmlFor="orderby">Ordenar por</label>
                    <select className="form-select m-0" onChange={(e) => setOrderBy(e.target.value)} defaultValue="name" name="orderby" aria-label="Ordenar por">
                        <option value="name">Nombre</option>
                        <option value="price asc">Menor precio</option>
                        <option value="price desc">Mayor precio</option>
                    </select>
                </span>

            </div >
            {
                loading ? (
                    <Loading />
                ) :
                    (
                        <>
                            {
                                (showAs === 'table') ?
                                    (<ItemTable onlyShow={false} items={items} updateItemsQuantity={updateItemsQuantity} />) :
                                    (<ItemCards onlyShow={false} items={items} updateItemsQuantity={updateItemsQuantity} />)
                            }
                            <div className='mx-auto'>
                                <div className='col col-12 mt-3 text-center'>
                                    {pages.map((page, index) => (
                                        (page === "...") ?
                                            (<span key={"page" + index} className="pagination-page m-3">...</span>) :
                                            (
                                                (currentPage === page) ?
                                                    (<span key={"page" + index} className="pagination-page-current m-3" onClick={() => setCurrentPage(page)}>[{page}]</span>)
                                                    :
                                                    (<span key={"page" + index} className="pagination-page m-3" onClick={() => setCurrentPage(page)}>[{page}]</span>)

                                            )
                                    ))}
                                </div>
                            </div>
                        </>
                    )
            }
        </>
    )
}
export default Items;
