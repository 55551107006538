
function Loading() {
    return (
        <div className='w-100 text-center p-5'>
            <div className="spinner-border text-warning" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export default Loading
