import Items from '../components/Items'

function Store({ showAs, setShowAs, appLoading, globalSearch, itemsUrlWithDiscount, items, setItems, itemsInCart, paymentMethod, setPaymentMethod, updateItemsQuantity }) {
    return (
        <div>
            <Items showAs={showAs} setShowAs={setShowAs} appLoading={appLoading} globalSearch={globalSearch} itemsUrlWithDiscount={itemsUrlWithDiscount} items={items} setItems={setItems} itemsInCart={itemsInCart} paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} updateItemsQuantity={updateItemsQuantity} />
        </div>
    )
}

export default Store
