import React, { useEffect, useState } from 'react'
import { FaDivide, FaMinus, FaPlus } from "react-icons/fa"
import { Link } from 'react-router-dom';
import Modal from '../layout/Modal';


function ItemCards({ onlyShow, items, updateItemsQuantity }) {
  const [idItem, setIdItem] = useState(0);
  const [itemName, setItemName] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [fractionable, setFractionable] = useState(0);

  const setQuantityModal = (idItem, itemName, quantity, fractionable) => {
    setIdItem(idItem);
    setItemName(itemName);
    setQuantity(quantity);
    setFractionable(fractionable);
  }

  const handleKeyUp = (e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      document.getElementById("quantityModalBtModalOK").click();
    }
  }

  useEffect(() => {
    document.getElementById("quantityModal").addEventListener('shown.bs.modal', function () {
      setTimeout(() => {
        document.getElementById("tbxCantidad").focus();
        document.getElementById("tbxCantidad").select();
      }, 200);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const imagesUrl = process.env.REACT_APP_BACKEND_URL + "/admin/app/uploads/";
  return (
    <>
      <Modal id="quantityModal" title={itemName} xl={false} textCloseBt="Cancelar" textOKBt="OK" callbackInOK={() => updateItemsQuantity(idItem, quantity, 0, fractionable)}>
        <div className='m-auto text-center'>
          <div className='d-inline-block'>
            <p className='text-start p-0 m-0'>
              <label htmlFor="tbxCantidad" className='v mr-2'>Cantidad</label>
            </p>
            <p>
              <input type="number" onKeyUp={(e) => { handleKeyUp(e) }} autoFocus={true} className='text-center ml-2' name="tbxCantidad" id="tbxCantidad" value={quantity} onChange={(e) => { setQuantity(e.target.value) }} />
            </p>
          </div>
        </div>
      </Modal>
      {items.length === 0 ? (<h2 className='mt-5 mb-5 p-2 text-center'>Nada por aquí</h2>) : ("")}
      <div className='row mx-auto'>
        {
          items.map((item, index) => (
            <div key={"item" + index} className="col col-6 col-md-4 col-xl-3 p-2">
              <div className="card bg-light bg-gradient h-100">
                <div className="card-body pt-2 pb-2 ps-1 pe-1 text-center">
                  <h6 className="card-title text-center">
                    <Link to={"/itemdetaills?idItem=" + item["id"]} className='item-link'>
                      {item["name"]}
                    </Link>
                  </h6>
                  <p className="card-text price text-center">${Number(item["price"]).toFixed(2)}</p>
                  {
                    (item["images"] != null) ?
                      (
                        <img className='img-fluid img-item mb-2' src={imagesUrl + item["images"]} alt={item["name"]} />
                      ) : ""
                  }

                  <div className='text-center'>
                    {onlyShow ?
                      (<>
                        <p className='text-center'>
                          {item["quantity"]}
                        </p>
                      </>) :
                      (<>
                        <FaMinus className='col-4 cart-add-remove noselection' onClick={() => updateItemsQuantity(item["id"], item["quantity"] * 1, -1, item["fractionable"] * 1)} title='Quitar del carrito' />
                        <span className='quantity col-4'>
                          <button type="button" onClick={() => setQuantityModal(item["id"], item["name"], item["quantity"], item["fractionable"] * 1)} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#quantityModal">
                            {
                              item["fractionable"] === "1" ?
                                (Number(item["quantity"], 0).toFixed(2)) :
                                (item["quantity"])
                            }
                          </button>
                        </span>
                        <FaPlus className='col-4 cart-add-remove noselection' onClick={() => updateItemsQuantity(item["id"], item["quantity"] * 1, 1, item["fractionable"] * 1)} title='Agregar al carrito' />
                        {
                          item["fractionable"] === "1" ?
                            (
                              <>
                                <p className="col col-12 text-center mt-3 p-info"><FaDivide /> Este producto se puede fraccionar</p>
                                <p className="col col-12 text-center">Está comprando {item["quantity"]} {item["fractionmeasurement"]}</p>
                              </>
                            ) : null
                        }
                      </>)
                    }
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </>
  )
}

export default ItemCards