import React, { useEffect, useState } from 'react'
import { FaShoppingCart, FaSearch, FaQrcode } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

function NavBar({ updateGlobalSearch, itemsInCart }) {
    const [searchValue, setSearchValue] = useState('');
    const [currentScroll, setCurrentScroll] = useState('0');
    const location = useLocation();

    useEffect(() => {
        const timeOutId = setTimeout(() => updateGlobalSearch(searchValue), 700);
        return () => clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleScroll = () => {
        setCurrentScroll(window.scrollY);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
    }

    const handleCollapse = () => {
        var nav = document.getElementById("navbarSupportedContent");
        var btn = document.getElementById("navbarBtn");
        nav.classList.remove("show");
        btn.classList.add("collapsed");
    };
    const backToSearch = () => {
        document.getElementById("mainSearch").focus();
        document.getElementById("mainSearch").select();
    };

    return (
        <>
            <div className={currentScroll > 150 && location.pathname !== "/cart" ? "fixed-icons fixed-icons-block" : "fixed-icons fixed-icons-hidden"}>
                <a href="./web/qr/scan.php" className='me-3 d-inline-block d-md-none'>
                    <FaQrcode className='qr-icon' />
                </a>
                <span onClick={() => backToSearch()} className='me-3'>
                    <FaSearch className='search-icon' />
                </span>
                <Link to="/cart" className='me-2'>
                    <FaShoppingCart />
                    <span className="items-in-cart">{itemsInCart.filter((current) => current["quantity"] > 0).length}</span>
                </Link>
            </div>

            <nav className="navbar navbar-expand-md navbar-light ">
                <div className="container-fluid">

                    <button className="navbar-toggler" id="navbarBtn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <Link to='/' className='pb-2 d-sm-block'>
                        <img src="./img/el-rebusque-logo.jpg" className="navbar-brand bg-white rounded p-1" alt="Supermercado El rebusque" width="100px" />
                    </Link>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">

                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 m-auto ">
                            <li className="nav-item">
                                <Link to="/" className="nav-link active" aria-current="page" onClick={handleCollapse}>
                                    Inicio
                                </Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 m-auto ">
                            <li className="nav-item">
                                <Link to="/store" className="nav-link active" aria-current="page" onClick={handleCollapse}>
                                    Productos
                                </Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 m-auto ">
                            <li className="nav-item">
                                <Link to="/we" className="nav-link active" aria-current="page" onClick={handleCollapse}>
                                    Nosotros
                                </Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 m-auto ">
                            <li className="nav-item">
                                <Link to="/contact" className="nav-link active" aria-current="page" onClick={handleCollapse}>
                                    Contacto
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <a href="./web/qr/scan.php" className='d-inline-block d-md-none'>
                        <FaQrcode className='qr-icon' />
                    </a>
                    <span className="nav-link ">
                        <Link to="/cart" onClick={handleCollapse}>
                            <FaShoppingCart className='cart-icon' />
                            <span className="items-in-cart">{itemsInCart.filter((current) => current["quantity"] > 0).length}</span>
                        </Link>
                    </span>

                </div>
            </nav>
            {
                (location.pathname === "/store" || location.pathname === "/") ?
                    (
                        <div className="row w-100 p-0 m-0">
                            <div className="col col-12 p-0 m-0">
                                <form onSubmit={handleSearchSubmit}>
                                    <input
                                        id="mainSearch"
                                        className="form-control mainSearch"
                                        type="search"
                                        placeholder="BUSCAR"
                                        aria-label="Buscar"
                                        autoComplete="off"
                                        value={searchValue}
                                        onChange={event => setSearchValue(event.target.value)}
                                    />
                                </form>
                            </div>
                        </div>
                    ) : ""
            }
        </>
    )
}

export default NavBar
