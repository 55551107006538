
function Modal({ id, title, textCloseBt, textOKBt, callbackInOK, children, xl }) {
    return (
        <div>
            <div className="modal fade" id={id} tabIndex="-1" aria-labelledby={title} aria-hidden="true">
                <div className={xl?"modal-dialog modal-dialog-scrollable modal-xl":"modal-dialog modal-dialog-scrollable"}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id={id}>{title}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{textCloseBt}</button>
                            <button id={id + "BtModalOK"} type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={callbackInOK}>{textOKBt}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal
