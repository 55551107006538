import React, { useEffect, useState } from 'react'
import { FaDivide, FaMinus, FaPlus } from "react-icons/fa"
import { Link } from 'react-router-dom';
import Modal from '../layout/Modal'


function ItemTable({ onlyShow, items, updateItemsQuantity }) {
  const [idItem, setIdItem] = useState(0);
  const [itemName, setItemName] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [fractionable, setFractionable] = useState(0);

  const setQuantityModal = (idItem, itemName, quantity, fractionable) => {
    setIdItem(idItem);
    setItemName(itemName);
    setQuantity(quantity);
    setFractionable(fractionable);
  }

  const handleKeyUp = (e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      document.getElementById("quantityModalBtModalOK").click();
    }
  }

  useEffect(() => {
    document.getElementById("quantityModal").addEventListener('shown.bs.modal', function () {
      setTimeout(() => {
        document.getElementById("tbxCantidad").focus();
        document.getElementById("tbxCantidad").select();
      }, 200);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Modal id="quantityModal" title={itemName} xl={false} textCloseBt="Cancelar" textOKBt="OK" callbackInOK={() => updateItemsQuantity(idItem, quantity, 0, fractionable)}>
        <div className='m-auto text-center'>
          <div className='d-inline-block'>
            <p className='text-start p-0 m-0'>
              <label htmlFor="tbxCantidad" className='v mr-2'>Cantidad</label>
            </p>
            <p>
              <input type="number" onKeyUp={(e) => { handleKeyUp(e) }} autoFocus={true} className='text-center ml-2' name="tbxCantidad" id="tbxCantidad" value={quantity} onChange={(e) => { setQuantity(e.target.value) }} />
            </p>
          </div>
        </div>
      </Modal>
      {
        (items.length === 0) ?
          (<h2 className='mt-5 mb-5 p-2 text-center'>Nada por aquí</h2>) :
          (
            <div className='mx-auto'>
              <div className="row p-0 m-0 pb-1 mt-1 bg-dark text-white ">
                <span className="col col-9 p-0 m-0 col-md-8">Producto</span>
                <span className="col col-3 p-0 m-0 col-md-2 text-end d-none d-md-block">Precio</span>
                <span className="col col-12 p-0 m-0 col-md-2 text-center d-none d-md-block">Cantidad</span>
              </div>

              {
                items.map((item, index) => (
                  <div key={"item" + index} className={"row p-0 m-0 pb-1 align-items-center row-hover " + ((index % 2 === 0) ? "striped-1" : "striped-2")}>
                    <span className="col col-9 col-md-7">
                      <Link to={"/itemdetaills?idItem=" + item["id"]} className='item-link'>
                        {item["name"]}
                      </Link>
                    </span>
                    <span className="col col-3 col-md-2 text-end price">
                      ${Number(item["price"]).toFixed(2)}
                    </span>
                    <div className='col col-12 col-md-3 text-center m-auto'>
                      <div className='row p-0 m-0 align-items-center'>
                        {onlyShow ?
                          (<>
                            <p className='text-center'>
                              {item["quantity"]}
                            </p>
                          </>) :
                          (<>
                            <FaMinus className='col-4 cart-add-remove p-0 noselection' onClick={() => updateItemsQuantity(item["id"], item["quantity"] * 1, -1, item["fractionable"] * 1)} title='Quitar del carrito' />
                            <span className='quantity col-4 p-0'>
                              <button type="button" onClick={() => setQuantityModal(item["id"], item["name"], item["quantity"], item["fractionable"] * 1)} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#quantityModal">
                                {
                                  item["fractionable"] === "1" ?
                                    (Number(item["quantity"], 0).toFixed(2)) :
                                    (item["quantity"])
                                }
                              </button>

                            </span>
                            <FaPlus className='col-4 cart-add-remove p-0 noselection' onClick={() =>   updateItemsQuantity(item["id"], item["quantity"] * 1, 1, item["fractionable"] * 1)} title='Agregar al carrito' />
                          </>)
                        }
                      </div>
                    </div>
                    {
                      item["fractionable"] === "1" ?
                        (
                          <div className="col col-12 text-center">
                            <span className='p-info'><FaDivide /> Este producto se puede fraccionar.</span>
                            
                            {item["quantity"] > 0 ?
                              <span className='ps-2 fw-bold'><br/>Está comprando {item["quantity"]} {item["fractionmeasurement"]}</span>
                              : null
                            }
                          </div>
                        ) : null
                    }
                  </div>
                ))
              }
            </div>
          )
      }
    </>
  )
}

export default ItemTable