import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ItemCards from './ItemsCards';
import ItemTable from './ItemsTable'
import { FaThList, FaRegIdCard, FaWhatsapp, FaHandPointRight, FaQuestionCircle, FaTrashAlt, FaCopy, FaMoneyBillAlt, FaCreditCard, FaCcMastercard, FaMoneyCheck, FaShippingFast, FaWalking } from "react-icons/fa"; import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import toast, { Toaster } from 'react-hot-toast';
import Modal from '../layout/Modal';
import { IconContext } from 'react-icons';

function Cart({ showAs, setShowAs, appLoading, clearCart, itemsUrlWithDiscount, itemsInCart, paymentMethod, setPaymentMethod, updateItemsQuantity }) {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [contentToSend, setcontentToSend] = useState('');
    const [shippingMode, setShippingMode] = useState('pickuponsite');
    const [discountText, setDiscountText] = useState('');
    const [comment, setComment] = useState('');

    useEffect(() => {
        if (!appLoading) {
            getAllItems(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsInCart, appLoading, itemsUrlWithDiscount])

    useEffect(() => {
        switch (paymentMethod) {
            case "cash/transfer":
                setDiscountText("El precio incluye un descuento del 10% por pago de contado");
                break;
            case "debit":
            case "credit":
                setDiscountText("El precio incluye un descuento del 5% por pago de contado");
                break;
            case "cc":
                setDiscountText("Precio sin descuento");
                break;
            default:
                setDiscountText("");
                break;

        }
    }, [paymentMethod])

    useEffect(() => {
        if (!appLoading) {
            generateContentToSend();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, itemsInCart, shippingMode, paymentMethod, comment])

    const getAllItems = async () => {
        if (itemsUrlWithDiscount === null) return;
        setLoading(true);

        if (itemsInCart.length > 0) {
            const _ids = itemsInCart.map((itemInCart) => {
                return itemInCart["id"];
            }).join();
            var ids = "0";
            if (_ids !== "") ids = _ids;

            //const query = itemsUrl + "&currentPage=1&orderBy=name&onlyIds=" + ids;
            const query = itemsUrlWithDiscount + "&currentPage=1&orderBy=name&onlyIds=" + ids;

            //Peticionar datos actualizados
            const response = await axios.get(query);
            //Restaurar items agregados
            const newItems = [...response.data.data];
            itemsInCart.forEach(itemInCart => {
                const item = newItems.find((item) => item["id"] === itemInCart["id"]);
                if (typeof item !== 'undefined')
                    item["quantity"] = itemInCart["quantity"];
            });
            setItems(newItems);
        }
        else {
            setItems([]);
        }
        setLoading(false)
    }

    const generateContentToSend = () => {
        if (items.length === 0) setcontentToSend("");
        else {
            const selectedPayMode = document.querySelector('input[name=btnPayModeRadio]:checked').dataset.text;
            const selectedShippingMode = document.querySelector('input[name=btnShippingModeRadio]:checked').dataset.text;
            setcontentToSend(
                items.map((itemInCart) => {
                    return itemInCart["quantity"] + "    " + itemInCart["name"];
                }).join("%0A%0A").replaceAll('&', 'y') + "%0A%0AComentario adicional:%0A" + comment + "%0A%0ATipo de pago: " + selectedPayMode + "%0A%0ATipo de envío: " + selectedShippingMode
            );
        }
    }

    const handleCleanCart = async () => {
        clearCart();
        toast.success("El carrito ha sido vaciado");
    };

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            toast.success("Copiado a portapapeles");
        } catch (err) {
            toast.error('No fue posible copiar al portapapeles. Intenta actualizando tu navegador.');
        }
    };

    /*  const enviarCarrito = ((url) => {
         const win = window.open(url);
         win.focus();
     }) */

    return (
        <>
            <div><Toaster position="bottom-center" /></div>
            <Modal id="confirmCleanModal" title={"Vaciando carrito"} xl={false} textCloseBt="Cancelar" textOKBt="Sí, Vaciar" callbackInOK={() => handleCleanCart()}>
                <div className='m-auto text-center'>
                    <div className='d-inline-block'>
                        <h3>¿Vaciar el carrito?</h3>
                    </div>
                </div>
            </Modal>
            <div className='row mx-auto bg-light pt-1 pb-1'>
                <span className='col col-12 col-xl-12 text-center'>
                    <div className="btn-group" role="group" aria-label="Mostrar como">
                        <input type="radio" onChange={(e) => e.preventDefault()} onClick={() => setShowAs("table")} className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" />
                        <label className={(showAs === 'table') ? 'btn btn-primary' : 'btn btn-secondary'} htmlFor="btnradio1" title="Mostrar en modo tabla"><FaThList /></label>

                        <input type="radio" onChange={(e) => e.preventDefault()} onClick={() => setShowAs("card")} className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                        <label className={(showAs === 'card') ? 'btn btn-primary' : 'btn btn-secondary'} htmlFor="btnradio2" title="Mostrar en modo tarjeta"><FaRegIdCard /></label>
                    </div>
                </span>
            </div>

            <>
                {
                    (showAs === 'table') ?
                        (<ItemTable onlyShow={false} items={items} updateItemsQuantity={updateItemsQuantity} />) :
                        (<ItemCards onlyShow={false} items={items} updateItemsQuantity={updateItemsQuantity} />)
                }
            </>

            {items.length !== 0 ?
                (
                    <>
                        <div className="row p-0 m-0 pb-1 pt-2 align-items-center row-hover">
                            <span className="col col-12 col-md-4"></span>
                            <span className="col col-12 col-md-4 text-end price"><span className='d-md-block d-none'>Total:</span></span>
                            <span className="col col-12 col-md-2 text-end price">
                                <span className='d-md-none d-inline-block'>* Total:</span>
                                {
                                    (loading) ?
                                        (
                                            <div className="spinner-border spinner-border-sm text-warning" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        )
                                        :
                                        (
                                            <>
                                                <a data-tip data-for='searchTip' className='pe-2'>
                                                    <FaQuestionCircle />
                                                </a>
                                                <ReactTooltip id="searchTip" type="info" effect="float">
                                                    {discountText}
                                                </ReactTooltip>
                                                <span>$
                                                    {
                                                        Number(
                                                            items.reduce((total, currentItem) => {
                                                                return total + (currentItem["quantity"] * currentItem["price"])
                                                            }, 0)
                                                        ).toFixed(2)
                                                    }
                                                </span>
                                            </>
                                        )
                                }

                            </span>
                        </div>
                        <IconContext.Provider value={{ size: "1.4em" }}>
                            <div className="row m-0 align-items-center row-gray">
                                <span className='col col-12 col-xl-6'>
                                    <label className='d-block text-center fw-bold mb-2' htmlFor="btnPayModeRadio">¿Cómo quiere pagar su pedido?</label>
                                    <div className="btn-group d-flex flex-column flex-md-row text-center" role="group" aria-label="Método de pago">
                                        <input readOnly={true} type="radio" data-text="Efectivo/Transferencia" checked={paymentMethod === 'cash/transfer'} onClick={() => setPaymentMethod("cash/transfer")} className="btn-check" name="btnPayModeRadio" id="btnPayModeRadio1" autoComplete="off" />
                                        <label className={(paymentMethod === 'cash/transfer') ? 'btn btn-success ' : 'btn btn-light'} htmlFor="btnPayModeRadio1" title="10% de descuento">Efectivo/Transferencia<br /><FaMoneyBillAlt /> <span className='discount'>-10%</span></label>

                                        <input readOnly={true} type="radio" data-text="Tarjeta de débito" checked={paymentMethod === 'debit'} onClick={() => setPaymentMethod("debit")} className="btn-check" name="btnPayModeRadio" id="btnPayModeRadio2" autoComplete="off" />
                                        <label className={(paymentMethod === 'debit') ? 'btn btn-success' : 'btn btn-light'} htmlFor="btnPayModeRadio2" title="5% de descuento">Débito<br /><FaCreditCard /><span className='discount'>-5%</span></label>

                                        <input readOnly={true} type="radio" data-text="Tarjeta de crédito" checked={paymentMethod === 'credit'} onClick={() => setPaymentMethod("credit")} className="btn-check" name="btnPayModeRadio" id="btnPayModeRadio3" autoComplete="off" />
                                        <label className={(paymentMethod === 'credit') ? 'btn btn-success' : 'btn btn-light'} htmlFor="btnPayModeRadio3" title="5% de descuento">Crédito<br /><FaCcMastercard /><span className='discount'>-5%</span></label>

                                        <input readOnly={true} type="radio" data-text="Cuenta corriente" checked={paymentMethod === 'cc'} onClick={() => setPaymentMethod("cc")} className="btn-check" name="btnPayModeRadio" id="btnPayModeRadio4" autoComplete="off" />
                                        <label className={(paymentMethod === 'cc') ? 'btn btn-success' : 'btn btn-light'} htmlFor="btnPayModeRadio4" title="">Cuenta corriente<br /><FaMoneyCheck /></label>
                                    </div>
                                </span>
                                <span className='col col-12 col-xl-6'>
                                    <label className='d-block text-center fw-bold mb-2' htmlFor="btnshippingModeradio">¿Enviamos su pedido o pasa a buscarlo?</label>
                                    <div className="btn-group d-flex flex-column flex-md-row text-center" role="group" aria-label="Método de envío">
                                        <input readOnly={true} type="radio" data-text="Quiero que me lo envíen" checked={shippingMode === 'sendtohome'} onClick={() => setShippingMode("sendtohome")} className="btn-check" name="btnShippingModeRadio" id="btnshippingModeradio1" autoComplete="off" />
                                        <label className={(shippingMode === 'sendtohome') ? 'btn btn-success' : 'btn btn-light'} htmlFor="btnshippingModeradio1" title="Enviar a domicilio">Quiero que me lo envíen<br /><FaShippingFast /></label>

                                        <input readOnly={true} type="radio" data-text="Pasaré a retirarlo" checked={shippingMode === 'pickuponsite'} onClick={() => setShippingMode("pickuponsite")} className="btn-check" name="btnShippingModeRadio" id="btnShippingModeRadio2" autoComplete="off" />
                                        <label className={(shippingMode === 'pickuponsite') ? 'btn btn-success' : 'btn btn-light'} htmlFor="btnShippingModeRadio2" title="Pasar a retirarlo">Pasaré a retirarlo<br /><FaWalking /></label>
                                    </div>
                                </span>

                            </div>
                        </IconContext.Provider>

                        <div className="splitter"></div>
                        <div className="d-block pt-4 text-center" aria-label="comentario">
                            <h4 className='mt-1 text-center'>¿Algúna duda o comentario?</h4>
                            <p className='text-center fw-bold'>Indicalo a continuación</p>
                            <div className='w-100 pt-0 p-3'>
                                <textarea className='d-flex w-100 rounded-3 p-2' value={comment} onChange={(e) => setComment(e.target.value)} />
                            </div>
                        </div>

                        <div className='row-gray mt-3'>
                            <h4 className='text-center'>FINALIZAR EL PEDIDO</h4>
                            <div className="btn-group d-flex" role="group" aria-label="Controles de carrito">
                                <button type="button" id="btCleanCart" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#confirmCleanModal">
                                    Vaciar carrito <FaTrashAlt />
                                </button>
                                <button type="button" className="btn btn-primary" onClick={() => copyToClipBoard("Nuevo pedido WEB:\n\n" + contentToSend.replaceAll("%0A", "\n"))}>
                                    Copiar al portapapeles <FaCopy />
                                </button>
                                <button type="button" title="Al enviar el pedido, nuestro personal le comunicará los detalles del mismo" className="btn btn-success">
                                    <a href={"https://web.whatsapp.com/send?phone=543535633858&text=Nuevo pedido WEB:%0A%0A" + contentToSend} className='whatsapp-link' target="_blank" rel="noopener noreferrer" onClick={() => document.getElementById("btCleanCart").click()}>
                                        <span className='d-none d-md-block'>
                                            <span className='me-1'>ENVIAR CARRITO</span><FaWhatsapp className='ms-1' />
                                        </span>
                                    </a>
                                    <a href={"https://api.whatsapp.com/send?phone=543535633858&text=Nuevo pedido WEB:%0A%0A" + contentToSend} className='whatsapp-link' target="_blank" rel="noopener noreferrer" onClick={() => document.getElementById("btCleanCart").click()}>
                                        <span className='d-block d-md-none'>
                                            <span className='me-1'>ENVIAR CARRITO</span><FaWhatsapp className='ms-1' />
                                        </span>
                                    </a>
                                </button>
                            </div>
                        </div>
                    </>
                ) : ""
            }
            <div className="splitter"></div>
            <div className="d-block text-center m-5" aria-label="Controles de carrito">
                <h3 className='text-center'>¿Olvidaste algo?</h3>
                <Link to="/store" className="custom-link text-center">
                    <FaHandPointRight className='me-1' />Seguir comprando
                </Link>
            </div>
        </>
    )
}
export default Cart;
