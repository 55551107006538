import CartComponent from '../components/Cart'

function Cart({ showAs, setShowAs, clearCart, itemsUrlWithDiscount, itemsInCart, paymentMethod, setPaymentMethod, updateItemsQuantity }) {
    return (
        <div>
            <CartComponent showAs={showAs} setShowAs={setShowAs} clearCart={clearCart} itemsUrlWithDiscount={itemsUrlWithDiscount} itemsInCart={itemsInCart} paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} updateItemsQuantity={updateItemsQuantity} />
        </div>
    )
}

export default Cart
