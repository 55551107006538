import SlideMain from '../components/SlideMain';
import { FaStoreAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <div>
            <SlideMain />

            <div className='p-5 text-center enter-store-content'>
                <Link to="/store" className='enter-store'>
                    < FaStoreAlt />
                    <p>ENTRAR</p>
                </Link>
            </div>


        </div>
    )
}

export default Home
