import WeComponent from '../components/We'

function We() {
  return (
    <>
      <WeComponent/>
    </>
  )
}
export default We
