
function Location() {
    return (
        <div className="m-auto text-center">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3382.874833580443!2d-62.91805348476342!3d-32.01850403283692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cc837f4159b31f%3A0xaade455767352fe6!2sSupermercado%20El%20Rebusque!5e0!3m2!1ses-419!2sar!4v1655237758307!5m2!1ses-419!2sar"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>


/*src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3382.874833580443!2d-62.91805348476342!3d-32.01850403283692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cc837f4159b31f%3A0xaade455767352fe6!2sSupermercado%20El%20Rebusque!5e0!3m2!1ses-419!2sar!4v1655237758307!5m2!1ses-419!2sar"
                width="600"
                height="450"
                style="border:0;"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
  */  )
}

export default Location
