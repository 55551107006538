import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Loading from './Loading';
import ItemTable from './ItemsTable'
import { FaHandPointRight } from "react-icons/fa";
import { Link, useSearchParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

function ItemDetaills({ showAs, setShowAs, appLoading, clearCart, itemsUrlWithDiscount, itemsInCart, updateItemsQuantity }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsInCart])

    const getItem = async () => {
        if (itemsUrlWithDiscount === null) return;
        setLoading(true);
        const idItem = searchParams.get("idItem");
        //const query = itemsUrl + "&currentPage=1&orderBy=name&onlyIds=" + idItem;
        const query = itemsUrlWithDiscount + "&currentPage=1&orderBy=name&onlyIds=" + idItem;

        //Peticionar datos actualizados
        const response = await axios.get(query);
        console.log(response);
        //Restaurar items agregados
        const newItems = [...response.data.data];
        itemsInCart.forEach(itemInCart => {
            const item = newItems.find((item) => item["id"] === itemInCart["id"]);
            if (typeof item !== 'undefined')
                item["quantity"] = itemInCart["quantity"];
        });
        setItems(newItems);
        setLoading(false)
    }
    const imagesUrl = process.env.REACT_APP_BACKEND_URL + "/admin/app/uploads/";
    return (
        <>
            <div><Toaster position="bottom-center" /></div>
            <div className='row mx-auto bg-light pt-1 pb-1'>
                <span className='col col-12 col-xl-6'>
                </span>
                <span className='col col-2 col-xl-2 text-end'>
                </span>
                <span className='col col-6 col-xl-2'>

                </span>
                <span className='col col-4 col-xl-2 text-center'>

                </span>
            </div>
            {loading ? (
                < Loading />
            ) :
                (
                    <>
                        <ItemTable onlyShow={false} items={items} updateItemsQuantity={updateItemsQuantity} />
                        {
                            (items[0]["images"] != null) ?
                                (
                                    <img className='img-thumbnail rounded mx-auto d-block' src={imagesUrl + items[0]["images"]} alt={items[0]["name"]} />
                                ) : ""
                        }
                    </>
                )
            }

            <div className="d-block text-center" aria-label="Controles de carrito">

                <Link to="/store" className="custom-link text-center">
                    <FaHandPointRight className='me-1' />Seguir comprando
                </Link>
            </div>
        </>
    )
}
export default ItemDetaills;
